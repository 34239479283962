.carousel.carousel-slider {
  overflow: unset !important;

  .legend {
    color: #fff;
    opacity: 1;
    font-weight: bold;
    text-shadow: 1px 1px rgba($color: #000000, $alpha: 0.6);
    font-size: 1.1em;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: unset;
    margin-left: 0;
  }
}

.slider.animated {
  padding-right: 15px !important;
}

.control-dots {
  display: flex;
  justify-content: center;
  bottom: -30px !important;

  li {
    margin-right: 10px;
  }
}

.slide {
  border-radius: 15px !important;
  margin-right: 15px !important;
  overflow: hidden;
}

.banner-item {
  background: linear-gradient(0deg, #20e0be 0%, #5e96ff 85.33%);
  width: 100%;
  height: 100%;
}
