body {
  margin: 0;
  min-height: 100vh;
  // background: #f4f4f4;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  // max-width: 1920px;
  margin: 0 auto;
}

.text-shadow{
  text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
}

.bg-gradient{
	&::after{
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgb(25, 36, 54) 100%);
    z-index: 2;
    pointer-events: none;
  }
}

.scroll-wrapper::-webkit-scrollbar {
  width: 0.25rem;
}

.scroll-wrapper::-webkit-scrollbar-track {
  background: rgb(0, 43, 83);
}

.scroll-wrapper::-webkit-scrollbar-thumb {
  background: rgb(246, 178, 5);
}
