.main-navbar {
  background-color: #000;
  background-image: url('../../assets/img/bg-navbar.png');
  border: 1px solid #182130;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .main-navbar {
    background-image: url('../../assets/img/bg-navbar-mobile.png');
  }
}