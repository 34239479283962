.ambassador {
  position: relative;
  width: 84px;
  height: 74px;
  &.active {
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      background: url('../../assets/img/decoration.png') no-repeat center;
      background-size: contain;
      width: 84px;
      height: 74px;
      pointer-events: none;
    }
  }
}
