// @import '../../styles/mixins.scss';

#room-wrapper::-webkit-scrollbar {
  height: 0.4rem;
}

#room-wrapper::-webkit-scrollbar-track {
  background: rgb(0, 43, 83);
}

#room-wrapper::-webkit-scrollbar-thumb {
  background: rgb(249, 166, 0);
}
